import config from 'config/configLoader';
import { Revision } from 'models/scenarios.server';
import { SimulationRequest } from 'models/simulation.server';
import { POI, poiLayerIdentifiers } from 'pages/builder/map';

export const GCMInputTemplate = {
  heatScoreTif:
    'https://stcitiespublic.blob.core.windows.net/assets/urbanage/green-comfort-model/input/ghent-lez/ghent-threshold-heat-stress-min-and-max.tif',
  toilet:
    'https://stcitiespublic.blob.core.windows.net/assets/urbanage/green-comfort-model/input/ghent-lez/toilet.json',
  calculationArea:
    'https://stcitiespublic.blob.core.windows.net/assets/urbanage/green-comfort-model/input/ghent-lez/calculation-area.json',
  drinkingWater:
    'https://stcitiespublic.blob.core.windows.net/assets/urbanage/green-comfort-model/input/ghent-lez/drinking_water.json',
  bench: 'https://stcitiespublic.blob.core.windows.net/assets/urbanage/green-comfort-model/input/ghent-lez/bench.json',
  park: 'https://stcitiespublic.blob.core.windows.net/assets/urbanage/green-comfort-model/input/ghent-lez/park.json',
  tree: 'https://stcitiespublic.blob.core.windows.net/assets/urbanage/green-comfort-model/input/ghent-lez/tree.json',
  postOutputToUrl: `${config.gcmApiUrl}dataset`,
  h3Resolution: 12,
  inputCity: 'ghent-lez',
};

export const formatPOItoRevision = (poi: POI, dataSourceId: string): Revision => {
  const patchObj = {
    op: 'add',
    path: `/features/-`,
    value: {
      type: 'Feature',
      geometry: {
        type: poi.geomType,
        coordinates: poi.coordinates,
      },
      properties: {
        type: poi.type,
      },
    },
  };

  const revision: Revision = {
    operation: 'create',
    dataSourceId,
    patch: JSON.stringify(patchObj),
  };

  return revision;
};

export const buildPatchURL = (scenarioId: string, datasourceId: string, snapshotId: string): string => {
  return `${config.interactionUrl}scenarios/${scenarioId}/datasources/${datasourceId}/${snapshotId}/revisions/patched`;
};

export const createGCMSimulationRequest = (
  changedTypes: string[],
  scenarioId: string,
  scenarioName: string,
  snapshotId: string,
): SimulationRequest => {
  if (snapshotId === '' || scenarioId === '') {
    throw Error('invalid id');
  }
  const simulationTemplate = { ...GCMInputTemplate };
  for (const type of changedTypes) {
    const ds = poiLayerIdentifiers.find((obj) => obj.type === type);
    if (ds) {
      const patchUrl = buildPatchURL(scenarioId, ds.id, snapshotId);
      const configPath = ds.gcmType || type.replace('ur:', '');
      (simulationTemplate as any)[configPath] = patchUrl;
    }
  }

  const simulation: SimulationRequest = {
    context: {
      project: 'urbanage',
      domain: 'dev',
    },
    workflow: {
      id: 'run-ur-model-green-comfort',
      version: 'v1',
      orchestrator: 'GCM',
    },
    scenario: {
      id: scenarioId,
      name: scenarioName,
      inputs: simulationTemplate,
    },
  };

  return simulation;
};
