import React, { useEffect } from 'react';

import { getData } from 'api/fapi';
import config from 'config/configLoader';
import L, { DrawMap } from 'leaflet';
import 'leaflet-draw';
import { v4 as uuidv4 } from 'uuid';

export type POI = {
  id: string;
  type: string;
  coordinates: any[];
  geomType: string;
};

export const poiLayerIdentifiers = [
  {
    id: 'benches-ghent-lez',
    name: 'Ghent LEZ - benches',
    type: 'ur:bench',
    gcmType: 'bench',
    icon: 'https://stcitiespublic.blob.core.windows.net/assets/icons/svg/bench.svg',
    addIcon: 'https://stcitiespublic.blob.core.windows.net/assets/icons/svg/bench-new.svg',
  },
  {
    id: 'trees-ghent-lez',
    name: 'Ghent LEZ - trees',
    type: 'ur:tree',
    gcmType: 'tree',
    icon: 'https://stcitiespublic.blob.core.windows.net/assets/icons/svg/tree.svg',
    addIcon: 'https://stcitiespublic.blob.core.windows.net/assets/icons/svg/tree-new.svg',
  },
  {
    id: 'toilets-ghent-lez',
    name: 'Ghent LEZ - toilets',
    type: 'ur:toilet',
    gcmType: 'toilet',
    icon: 'https://stcitiespublic.blob.core.windows.net/assets/icons/svg/toilet.svg',
    addIcon: 'https://stcitiespublic.blob.core.windows.net/assets/icons/svg/toilet-new.svg',
  },
  {
    id: 'drinking-water-ghent-lez',
    name: 'Ghent LEZ - drinking water',
    type: 'ur:drinking_water',
    gcmType: 'drinkingWater',
    icon: 'https://stcitiespublic.blob.core.windows.net/assets/icons/svg/drinking_water.svg',
    addIcon: 'https://stcitiespublic.blob.core.windows.net/assets/icons/svg/drinking_water-new.svg',
  },
  {
    id: 'parks-ghent-lez',
    name: 'Ghent LEZ - park',
    type: 'ur:park',
    gcmType: 'park',
    icon: '',
    addIcon: '',
  },
];

interface MapProps {
  isDrawing: boolean;
  currentPOIType: string;
  revisions: POI[];
  onAddRevision: (revision: POI) => void;
  onDrawChange: (isDrawing: boolean) => void;
}

let map: L.Map;
let layerControl: L.Control.Layers;
let drawLayer: L.GeoJSON;

export default function LeafletMap({ isDrawing, currentPOIType, revisions, onAddRevision, onDrawChange }: MapProps) {
  const initMap = () => {
    // create leaflet map
    map = L.map('map', {
      renderer: L.canvas(),
    }).setView([51.05222, 3.724153], 14);

    // add osm background
    const osm = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    }).addTo(map);

    const heatscoreLayer = L.tileLayer.wms(`${config.wmsEndpoint}?tiled=true`, {
      layers: config.heatstressLayer,
      format: 'image/png',
      transparent: true,
      maxZoom: 19,
      attribution: '&copy; <a href="https://www.imec.be">imec</a>',
    });
    // .addTo(map); // Heatstress layer is not added by default

    // add gcm
    const gcmLayer = L.tileLayer
      .wms(`${config.wmsEndpoint}?CQL_FILTER=simulationid+=+%27baseline%27`, {
        layers: config.gcmLayerBaseline,
        format: 'image/png',
        transparent: true,
        maxZoom: 19,
        attribution: '&copy; <a href="https://www.imec.be">imec</a>',
      })
      .addTo(map);

    const baseLayers = {
      OpenStreetMap: osm,
    };

    layerControl = L.control.layers(baseLayers).addTo(map);
    layerControl.addOverlay(heatscoreLayer, 'Ghent LEZ - Heatstress');
    layerControl.addOverlay(gcmLayer, 'Ghent LEZ - GCI');

    // add draw layer (for added POI's)
    drawLayer = L.geoJSON().addTo(map);
    drawLayer.setZIndex(10);
  };

  const createMarker = (feature: any, latlng: any, isNew = false) => {
    const poi = poiLayerIdentifiers.find((layer) => layer.type === feature.properties.type);

    if (poi) {
      const iconLink = isNew ? poi.addIcon : poi.icon;
      const icon = L.icon({
        iconUrl: iconLink,
        iconSize: [24, 24],
        iconAnchor: [12, 12],
      });
      return L.marker(latlng, { icon });
    }
    return L.marker(latlng);
  };

  const loadPoiLayersData = () => {
    poiLayerIdentifiers.forEach(async (poiLayerIdentifier) => {
      const data = await getData(config.federatedUrl + poiLayerIdentifier.id);
      const poiLayer = {
        id: poiLayerIdentifier.id,
        label: poiLayerIdentifier.name,
        data,
      };

      const geojsonLayer = L.geoJSON(poiLayer.data, {
        pointToLayer: createMarker,
        style() {
          return { color: '#008000' }; // color of polygons (ie parks)
        },
      }).addTo(map);
      layerControl.addOverlay(geojsonLayer, poiLayer.label);
    });
  };

  useEffect(() => {
    initMap();
    loadPoiLayersData();
  }, []);

  useEffect(() => {
    if (isDrawing) {
      if (currentPOIType === 'ur:park') {
        // draw polygon
        const drawOptions = {
          allowIntersection: false, // Restricts shapes to simple polygons
          drawError: {
            color: '#ce4321', // Color the shape will turn when intersects
            message: "<strong>Oh snap!<strong> you can't draw that!", // Message that will show when intersect
          },
          shapeOptions: {
            color: '#008000',
          },
        };
        new L.Draw.Polygon(map as DrawMap, drawOptions).enable();
        map.on('draw:created', (e: any) => {
          const { layer } = e;
          const geojson = layer.toGeoJSON();

          onAddRevision({
            id: uuidv4(),
            type: currentPOIType,
            coordinates: geojson.geometry.coordinates,
            geomType: geojson.geometry.type,
          });
          onDrawChange(false);
        });
      } else {
        map.addEventListener('click', (event) => {
          onAddRevision({
            id: uuidv4(),
            type: currentPOIType,
            coordinates: [event.latlng.lng, event.latlng.lat],
            geomType: 'Point',
          });
          onDrawChange(false);
        });
      }
    } else if (map.hasEventListeners('click')) map.removeEventListener('click');
  }, [isDrawing, currentPOIType]);

  useEffect(() => {
    drawLayer.clearLayers();
    const group = L.featureGroup();
    revisions.forEach((revision) => {
      if (revision.geomType === 'Point') {
        const marker = createMarker(
          { properties: { type: revision.type } },
          L.latLng(revision.coordinates[1], revision.coordinates[0]),
          true,
        );
        marker.bindTooltip(revision.type).addTo(group);
      }
      if (revision.geomType === 'Polygon') {
        const latLngCoords = revision.coordinates[0].map((coord: any) => L.latLng(coord[1], coord[0]));
        L.polygon([latLngCoords], { color: 'blue' }).bindTooltip(revision.type).addTo(group);
      }
    });

    group.addTo(drawLayer);
  }, [revisions]);

  return <div id="map" style={{ height: 600, width: 800 }} className="rounded-lg" />;
}
