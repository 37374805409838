import { IsNumber, IsString, IsUrl } from 'class-validator';

class Configuration {
  @IsUrl()
  federatedUrl!: string;

  @IsUrl()
  wmsEndpoint!: string;

  @IsString()
  gcmLayerBaseline!: string;

  @IsString()
  @IsUrl()
  interactionUrl!: string;

  @IsString()
  @IsUrl()
  simulationUrl!: string;

  @IsString()
  heatstressLayer!: string;

  @IsString()
  @IsUrl()
  gcmApiUrl!: string;

  @IsNumber()
  refreshInterval?: number;

  constructor(config: Configuration) {
    Object.assign(this, config);
  }
}

export default Configuration;
