export const extendUrlWithParams = (baseURL: string, params?: Record<string, string>) => {
  if (!params) {
    return baseURL;
  }
  const searchParams = new URLSearchParams(params);
  let newBaseUrl = baseURL;
  if (baseURL.includes('?')) {
    if (baseURL.split('?')[1].length > 0) newBaseUrl += '&';
  } else if (params) newBaseUrl += '?';
  return newBaseUrl + searchParams;
};

export const getData = async (url: string, params?: Record<string, string>): Promise<any> => {
  const extendedUrl = extendUrlWithParams(url, params);
  const response = await fetch(extendedUrl);
  const json = await response.json();
  return json;
};

export const getDatasetData = async (url: string, params?: Record<string, string>) => {
  return getData(url, params);
};
