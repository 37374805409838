import React, { ReactNode } from 'react';

import config, { runValidation } from 'config/configLoader';

interface Props {
  children?: ReactNode;
}

function ConfigValidator({ children }: Props) {
  const errors = runValidation(config);
  if (errors.length > 0) {
    console.error(errors);
    throw new Error(`Invalid configuration ${errors}}`);
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export default ConfigValidator;
