import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ChevronRightIcon, EllipsisHorizontalCircleIcon, ClipboardDocumentIcon } from '@heroicons/react/20/solid';
import config from 'config/configLoader';
import { getScenarioList, PAGE_SIZE, Scenario, START_PAGE_NUMBER } from 'models/scenarios.server';
import { classNames } from 'utils/classTools';

export default function OverviewPage(/* { role }: { role: string } */) {
  const [scenarios, setScenarios] = useState<Scenario[]>([]);
  const [page, setPage] = useState(1);
  const [canLoadMore, setCanLoadMore] = useState(false);

  const copyToClipboard = async (simId: string) => {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(simId).then(
        () => {
          toast.success('Simulation ID copied to clipboard!');
          return true;
        },
        () => {
          toast.error('Could not copy to clipboard!');
          return false;
        },
      );
    }
    // old, deprecated way of copying to clipboard, added as fallback
    return document.execCommand('copy', true, simId);
  };

  useEffect(() => {
    let ignore = false;
    setScenarios([]);
    (async () => {
      const results = await getScenarioList(page);
      if (!ignore) {
        if (page === START_PAGE_NUMBER) {
          setScenarios(results);
        } else {
          setScenarios([...scenarios, ...results]);
        }
        setCanLoadMore(results.length >= PAGE_SIZE);
      }
    })();
    return () => {
      ignore = true;
    };
  }, [page]);

  useEffect(() => {
    // set refresh on scenario list if needed
    if (config.refreshInterval && config.refreshInterval > 0) {
      setInterval(() => {
        (async () => {
          const results = await getScenarioList(page);
          if (page === START_PAGE_NUMBER) {
            setScenarios(results);
          } else {
            setScenarios([...scenarios, ...results]);
          }
          setCanLoadMore(results.length >= PAGE_SIZE);
        })();
      }, config.refreshInterval);
    }
  }, []);

  const loadMore = () => {
    setPage(page + 1);
  };

  return (
    <main className="mx-auto max-w-4xl px-0 sm:px-6 lg:px-8 mt-20">
      <header className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between mb-5">
        <h1 className="leading-6 text-ur-text">Scenarios</h1>
        {/* {role === 'Citizen' && ( */}
        <nav className="mt-3 sm:mt-0 sm:ml-4 ">
          <ul>
            <li>
              <NavLink
                to="/builder"
                className="inline-flex items-center rounded-md bg-ur-primary-button px-3 py-2 text-sm font-semibold text-white shadow-sm hover:opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ur-primary"
              >
                New Scenario
              </NavLink>
            </li>
          </ul>
        </nav>
        {/* )} */}
      </header>
      <div className="overflow-hidden bg-white shadow sm:rounded-md mx-auto max-w-4xl">
        <ul className="divide-y divide-gray-200">
          {scenarios.map((scenario) => (
            <li key={scenario.id}>
              <div className="block hover:bg-gray-50">
                <div className="flex items-center px-4 py-4 sm:px-6">
                  <div className="flex min-w-0 flex-1 items-center">
                    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                      <div>
                        <p className="truncate text-sm font-medium text-ur-primary">{scenario.scenarioName}</p>
                        <p className="mt-2 flex justify-between text-sm text-gray-500">
                          <span className="truncate">{scenario.lastSimulationId}</span>

                          <button
                            type="button"
                            className="w-8 min-w-0"
                            onClick={() => copyToClipboard(`${scenario.lastSimulationId}`)}
                          >
                            <ClipboardDocumentIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </button>
                        </p>
                      </div>
                      <div className="hidden md:block">
                        <div>
                          {scenario.lastSimulationId ? (
                            <p className="text-sm text-gray-900">
                              Requested on <time dateTime={scenario.date}>{scenario.dateFull}</time>
                            </p>
                          ) : (
                            <p className="text-sm text-gray-900">No simulations found</p>
                          )}

                          <p className="mt-2 flex items-center text-sm text-gray-500">
                            <EllipsisHorizontalCircleIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0 text-ur-primary opacity-80"
                              aria-hidden="true"
                            />
                            {scenario.stage}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <nav
          className={classNames(
            scenarios.length ? 'border-t border-gray-200' : '',
            'flex items-center justify-between bg-white px-0 sm:px-6 lg:px-8 py-3',
          )}
          aria-label="Pagination"
        >
          <div className="hidden sm:block">
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">{scenarios.length}</span> scenarios
            </p>
          </div>
          {canLoadMore ? (
            <div className="flex flex-1 justify-center sm:justify-end">
              <button
                type="button"
                className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                onClick={() => loadMore()}
              >
                Load more
              </button>
            </div>
          ) : null}
        </nav>
      </div>
    </main>
  );
}
