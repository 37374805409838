import config from 'config/configLoader';

import { getSimulationList } from './simulation.server';

export interface Scenario {
  // Identifier of the scenario
  id: string;
  // Friendly name of the scenario
  scenarioName?: string;
  // The identifier of the last simulation for this scenario if available
  lastSimulationId?: string;
  // The UTC timestamp of the last simulation for this scenario, if available
  date?: string;
  // The friendly date of the last simualtion
  dateFull?: string;
  // The status of the last simulation
  stage?: string;
  // Link to open when clicking on this scenario
  href?: string;
}

export interface Revision {
  operation: string;
  dataSourceId: string;
  patch: string;
}

// The scenario object received from the IAPI
interface RawScenario {
  // Identifier of the scenario
  id: string;
  // Friendly name of the scenario, if available
  name?: string;
}

// The first page index
export const START_PAGE_NUMBER = 1;

// The size of the page, number of items to be loaded
export const PAGE_SIZE = 10;

const FriendlyDateOptions: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year: 'numeric' };

// Retrieve an ordered list of scenarios and their last simulation status if available
export async function getScenarioList(page: number) {
  // first we load a page of scenarios
  let url = `${config.interactionUrl}scenarios`;
  const params = new URLSearchParams({
    limit: `${PAGE_SIZE}`,
  });
  if (page > START_PAGE_NUMBER) {
    params.append('skip', `${(page - 1) * PAGE_SIZE}`);
  }
  url += `?${params.toString()}`;
  const response = await fetch(url);
  const scenarioList: RawScenario[] = await response.json();

  const results: Scenario[] = [];
  // we load all simulations and join the two sets, a bit of dark magic unti the SAPI supports pagination
  const simulations = await getSimulationList();
  console.debug({ scenarioList, simulations });
  for (let ix = 0; ix < scenarioList.length; ix++) {
    const scenario = scenarioList[ix];
    const lastSimulation = simulations.find((s) => s.scenario.id === scenario.id);

    let date = '2023-03-20T14:29:21.201Z';
    let asDate = new Date(date);
    if (lastSimulation) {
      // must be a valid global date and time string
      date = lastSimulation.timestamp || '2023-03-20T14:29:21.201Z';
      // produce a human friendly version
      asDate = new Date(date);
      const dateFull = asDate.toLocaleDateString('en-BE', FriendlyDateOptions);
      results.push({
        id: scenario.id,
        scenarioName: scenario.name || scenario.id,
        lastSimulationId: lastSimulation.id,
        date,
        dateFull,
        stage: lastSimulation.status,
        href: undefined, // TODO: eventually, use a link that "opens" a scenario with the ATC viewer
      });
    } else {
      results.push({
        id: scenario.id,
        scenarioName: scenario.name || scenario.id,
      });
    }
  }

  console.debug({ results });
  return results;
}

export async function createScenario(scenarioName: string) {
  const url = `${config.interactionUrl}scenarios`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name: scenarioName,
    }),
  });
  const result = await response.json();
  if (response.status !== 200) {
    throw new Error(result.message);
  }
  return result;
}

export async function createRevision(scenarioId: string, revisionId: string, revision: Revision) {
  const url = `${config.interactionUrl}scenarios/${scenarioId}/revisions/${revisionId}`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(revision),
  });
  if (response.status !== 200) {
    throw new Error('failed to store revision');
  }
  return response;
}

export async function createSnapshot(scenarioId: string) {
  const url = `${config.interactionUrl}scenarios/${scenarioId}/snapshots`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const result = await response.json();
  if (response.status !== 200) {
    throw new Error(result.message);
  }
  return result;
}
