import Configuration from './types';
// Our default configuration
// Type is also unknown as some required fields might be set in the environment config, see configLoader.ts
const defaultConfig: Configuration = {
  federatedUrl: 'https://federated.dev.ecosystem-urbanage.eu/v1/federated/',
  wmsEndpoint: 'https://geo.dev.ecosystem-urbanage.eu/geoserver/GCI/wms',
  gcmLayerBaseline: 'GCI:gcm_scores_geom',
  interactionUrl: 'https://interaction.dev.ecosystem-urbanage.eu/v1/interaction/', // datasource returning a list of scenarios to be displayed in the overview
  simulationUrl: 'https://simulation.dev.ecosystem-urbanage.eu/v1/simulation/', // datasource returning a list of scenarios to be displayed in the overview
  heatstressLayer: 'GCI:heatstress-flanders',
  gcmApiUrl: 'https://gcm.dev.ecosystem-urbanage.eu/v1/',
  refreshInterval: 60000,
};

export default defaultConfig as Configuration;
