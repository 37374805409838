import { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import jwt_decode from 'jwt-decode';
import Builder from 'pages/builder';
import Overview from 'pages/overview';

export default function App() {
  const [userRoles, setUserRoles] = useState<string[]>();

  // check JWT token and decode
  window.addEventListener('message', (event) => {
    const token = event.data.accessToken;
    if (token) {
      const decodedToken: any = jwt_decode(token);
      console.debug('Resource Access:', decodedToken.resource_access);
      setUserRoles(decodedToken.resource_access.urbanage?.roles || []);
    }

    console.debug('Detected role(s):', userRoles);
  });

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Overview /* role={userRole} */ />} />
        <Route path="/builder" element={<Builder /* role={userRole} */ />} />
      </Routes>
      <ToastContainer autoClose={3000} />
    </Router>
  );
}
