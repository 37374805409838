// There is no Error Boundaries in hooks yet
import React, { Component, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <div className="flex overflow-hidden h-screen sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6 rounded bg-white shadow m-auto">
            <h1 className="text-lg font-medium leading-6 text-gray-900 text-center">Oops! Something went wrong</h1>
            <p className="text-center">
              Try{' '}
              <button
                type="button"
                className="mt-4 border-b text-imec-primary border-b-imec-primary hover:text-imec-primary-dark"
                onClick={() => window.location.reload()}
              >
                refreshing
              </button>
            </p>
          </div>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
