import { plainToClass } from 'class-transformer';
import { validateSync, ValidationError } from 'class-validator';

import defaultConfig from './defaultConfig';
import Configuration from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const config: Configuration = { ...defaultConfig, ...(window as any).env };

export const runValidation = (cfg: Configuration): ValidationError[] => {
  return validateSync(plainToClass(Configuration, cfg));
};

if (process.env.NODE_ENV !== 'test') {
  console.debug('Using config:', config);
}

export default config;
