import config from 'config/configLoader';

export interface SimulationScenario {
  id: string;
  name?: string;
  inputs?: any;
}

export interface RawSimulation {
  id: string;
  scenario: SimulationScenario;
  status: string;
  timestamp?: string;
}

export interface SimulationContext {
  project: string;
  domain: string;
}

export interface SimulationWorkflow {
  id: string;
  version: string;
  orchestrator: string;
}

export interface SimulationRequest {
  context: SimulationContext;
  workflow: SimulationWorkflow;
  scenario: SimulationScenario;
}

// Retrieve a list of all simulations
export async function getSimulationList(): Promise<RawSimulation[]> {
  const url = config.simulationUrl;
  const response = await fetch(url);
  const json = await response.json();
  return json;
}

export async function startSimulation(simulation: SimulationRequest): Promise<any> {
  const url = config.simulationUrl;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(simulation),
  });
  if (response.status !== 200) {
    throw new Error('failed to start simulation');
  }
  return response;
}
