/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { TrashIcon } from '@heroicons/react/20/solid';
import { createRevision, createScenario, createSnapshot } from 'models/scenarios.server';
import { startSimulation } from 'models/simulation.server';
import { createGCMSimulationRequest, formatPOItoRevision } from 'utils/builderUtils';

import LeafletMap, { POI, poiLayerIdentifiers } from './map';

function Builder(/* { role }: { role: string } */) {
  const navigate = useNavigate();
  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const [scenarioName, setScenarioName] = useState<string>('');
  const [selectedPOIType, setSelectedPOIType] = useState<string>('ur:bench');
  const [revisions, setRevisions] = useState<any[]>([]);

  const startDrawing = () => {
    setIsDrawing(true);
  };

  const setSelectedPOI = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDrawing(false);
    setSelectedPOIType(e.target.value);
  };

  const removeRevision = (revisionId: string) => {
    if (revisionId) {
      const updatedRevisions = revisions.filter((poi) => poi.id !== revisionId);
      setRevisions(updatedRevisions);
    }
  };

  const addRevision = (revision: POI) => {
    setRevisions([...revisions, revision]);
  };

  const initSimulation = async () => {
    let snapshotResponse;
    const changedTypes = new Set<string>();
    let scenarioResponse = { id: scenarioName };

    if (scenarioName.length >= 40) {
      toast.error('Scenario name must be less than 40 characters');
      return;
    }
    // store scenario, revisions and create snapshot
    try {
      if (scenarioName !== '' || revisions.length !== 0) {
        scenarioResponse = await createScenario(scenarioName);
        const revisionResults = [];
        for (const poi of revisions) {
          changedTypes.add(poi.type);
          const ds = poiLayerIdentifiers.filter((poiLayer) => poiLayer.type === poi.type);
          const formatedRev = formatPOItoRevision(poi, ds[0].id);
          revisionResults.push(createRevision(scenarioResponse.id, poi.id, formatedRev));
        }
        await Promise.all(revisionResults);
        snapshotResponse = await createSnapshot(scenarioResponse.id);
      }
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong while creating the scenario');
    }

    // start simulation
    try {
      toast.success('Simulation started');

      const simRequest = createGCMSimulationRequest(
        Array.from(changedTypes),
        scenarioResponse.id,
        scenarioName,
        snapshotResponse.id,
      );
      console.debug(simRequest);

      await startSimulation(simRequest);
      navigate('/');
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong while starting the simulation');
    }
  };

  return (
    <div className="w-full min-h-full pt-20">
      <div className="flex flex-row w-full h-full justify-center">
        <div className="flex w-1/5 border bg-ur-secondary-background rounded-lg mr-6">
          <div className="flex flex-col w-full p-5">
            <div className="relative w-full mb-3">
              <input
                type="text"
                className="peer m-0 block h-[58px] w-full text-sm font-semibold rounded border border-solid border-neutral-300 bg-white bg-clip-padding py-4 px-3 leading-tight text-neutral-700 ease-in-out placeholder:text-transparent focus:border-primary focus:bg-white focus:pt-[1.625rem] focus:pb-[0.625rem] focus:text-neutral-700 focus:shadow-te-primary focus:outline-none [&:not(:placeholder-shown)]:pt-[1.625rem] [&:not(:placeholder-shown)]:pb-[0.625rem]"
                id="scenarioInput"
                placeholder="Scenario name"
                onChange={(e) => setScenarioName(e.target.value)}
                value={scenarioName}
              />
              <label
                htmlFor="scenarioInput"
                className="pointer-events-none absolute text-sm font-semibold top-0 left-0 origin-[0_0] border border-solid border-transparent py-4 px-3 text-neutral-700 transition-[opacity,_transform] duration-100 ease-in-out peer-focus:translate-x-[0.15rem] peer-focus:-translate-y-2 peer-focus:scale-[0.85] peer-focus:opacity-[0.65] peer-[:not(:placeholder-shown)]:translate-x-[0.15rem] peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:scale-[0.85] peer-[:not(:placeholder-shown)]:opacity-[0.65] motion-reduce:transition-none"
              >
                Scenario name
              </label>
            </div>
            <div className="mb-3">
              <select
                data-te-select-init
                onChange={setSelectedPOI}
                className="w-full rounded border border-solid border-neutral-300 bg-white text-gray-900 px-3 py-2 text-sm font-semibold"
              >
                <option value="ur:bench">Bench</option>
                <option value="ur:tree">Tree</option>
                <option value="ur:toilet">Toilet</option>
                <option value="ur:drinking_water">Drinking water</option>
                <option value="ur:park">Park</option>
              </select>
            </div>
            <div className="w-full flex justify-center mb-4">
              {!isDrawing && (
                <button
                  type="button"
                  className="relative ml-3 w-2/3 text-center rounded-md bg-ur-primary-button px-3 py-2 text-sm font-semibold text-white hover:opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ur-primary"
                  onClick={startDrawing}
                >
                  Select point on map
                </button>
              )}
              {isDrawing && (
                <button
                  type="button"
                  className="relative ml-3 w-2/3 text-center rounded-md bg-ur-secondary-button px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                  onClick={() => setIsDrawing(false)}
                >
                  Cancel
                </button>
              )}
            </div>
            <div className="w-full mt-4 h-[340px] max-h-[340px] overflow-y-auto overflow-x-hidden">
              <ul className="divide-y divide-gray-200 ml-4">
                {revisions.map((revision) => (
                  <li key={revision.id} className="flex flex-row justify-between mb-2 pt-2">
                    <div>
                      <p className="text-sm font-medium text-ur-primary">{revision.type}</p>
                      {revision.type !== 'ur:park' && (
                        <p className="mt-2 flex items-center text-xs text-gray-500">
                          <span className="">{revision.coordinates.join(', ')}</span>
                        </p>
                      )}
                    </div>
                    <button
                      type="button"
                      className="w-[30px] min-w-0 h-[30px]"
                      onClick={() => {
                        removeRevision(revision.id);
                      }}
                    >
                      <div className="h-5 w-5 text-gray-400 hover:text-gray-800">
                        <TrashIcon aria-hidden="true" />
                      </div>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <div className="flex justify-center mt-3">
              {revisions.length !== 0 && (
                <button
                  type="button"
                  className="relative ml-3 w-2/3 text-center rounded-md bg-ur-primary-button px-3 py-2 text-sm font-semibold text-white hover:opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ur-primary"
                  onClick={initSimulation}
                >
                  Save & Start simulation
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="rounded-lg">
          <LeafletMap
            isDrawing={isDrawing}
            revisions={revisions}
            currentPOIType={selectedPOIType}
            onAddRevision={addRevision}
            onDrawChange={setIsDrawing}
          />
        </div>
      </div>
    </div>
  );
}

export default Builder;
